<template>
    <footer>
        <div class="pt-11 lg:pt-16 pb-8 lg:pb-10 overflow-hidden bg-white">
            <div class="container">
                <div class="grid grid-cols-12 lg:gap-x-8">
                    <div class="col-span-12 lg:col-span-7">
                        <div class="grid grid-cols-12 gap-y-6 lg:gap-y-0 lg:gap-x-8 h-full">
                            <div class="col-span-12 flex mb-2 lg:mb-10 justify-start">
                                <Logo white />
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <h3
                                    class="lg:mb-4 font-Inter-Bold text-base lg:text-base footer-block-title mb-4 text-black"
                                    :class="{ active: isCategoriesVisible }"
                                    @click="$device.isMobile && (isCategoriesVisible = !isCategoriesVisible)"
                                >
                                    Kategóriák
                                </h3>
                                <transition name="slide" mode="in-out">
                                    <div
                                        v-if="($device.isMobile && isCategoriesVisible) || $device.isDesktop"
                                        class="flex flex-col gap-y-4 overflow-hidden"
                                    >
                                        <nuxt-link
                                            v-for="category in categories"
                                            :key="`category-${category?.id}`"
                                            :to="
                                                localePath({
                                                    name: 'products-category',
                                                    params: { category: category.translations[0].slug },
                                                })
                                            "
                                            class="text-sm font-Inter-Regular hover:underline text-black"
                                            >{{ category.translations[0]?.name }}
                                        </nuxt-link>
                                    </div>
                                </transition>
                            </div>
                            <div class="col-span-12 lg:col-span-4">
                                <h3
                                    class="lg:mb-4 font-Inter-Bold text-base lg:text-lg footer-block-title mb-4 text-black"
                                    :class="{ active: isInformationsVisible }"
                                    @click="$device.isMobile && (isInformationsVisible = !isInformationsVisible)"
                                >
                                    Információk
                                </h3>
                                <transition name="slide" mode="in-out">
                                    <div
                                        v-if="($device.isMobile && isInformationsVisible) || $device.isDesktop"
                                        class="flex flex-col gap-y-4 overflow-hidden"
                                    >
                                        <nuxt-link
                                            v-for="info in pages"
                                            :key="`category-${info.id}`"
                                            :to="`/${info.translations[0].slug}`"
                                            class="text-sm font-Inter-Regular hover:underline text-black"
                                            >{{ info.translations[0].title }}
                                        </nuxt-link>
                                    </div>
                                </transition>
                            </div>
                            <div class="col-span-12 lg:col-span-5">
                                <nuxt-link
                                    :to="localePath('products')"
                                    class="py-5 px-7 rounded-lg border border-opacity-10 flex bg-white bg-opacity-0 items-center gap-x-4 mb-4 footer-lg-btn border-black"
                                >
                                    <img :src="require('~/assets/images/icons/gallery-icon.svg')" alt="Gallery icon" />
                                    <div class="flex flex-col text-black">
                                        <span><strong>Galériánk</strong></span>
                                        <p class="font-Inter-Regular mt-1 text-xs">
                                            Tekintsd meg galériánkat és válaszd ki kedvenc képedet!
                                        </p>
                                    </div>
                                </nuxt-link>
                                <nuxt-link
                                    :to="localePath('products')"
                                    class="py-5 px-7 rounded-lg border border-opacity-10 flex bg-white bg-opacity-0 items-center gap-x-4 footer-lg-btn mb-3 border-black"
                                >
                                    <img :src="require('~/assets/images/icons/image-icon.svg')" alt="Picture icon" />
                                    <div class="flex flex-col text-black">
                                        <span><strong>Kiemelt kategóriák</strong></span>
                                        <p class="font-Inter-Regular mt-1 text-xs">
                                            Válogass kiemelt kategóriáinkból és szerkeszd meg egyedi ajándékodat!
                                        </p>
                                    </div>
                                </nuxt-link>
                                <nuxt-link
                                    v-if="false"
                                    to="/rendeles/kosar"
                                    class="py-5 px-7 rounded-lg border border-opacity-10 flex bg-white bg-opacity-0 items-center gap-x-4 footer-lg-btn border-black"
                                >
                                    <object
                                        type="image/svg+xml"
                                        :data="require('~/assets/images/icons/cart-icon--black.svg')"
                                    ></object>
                                    <div class="flex flex-col text-black">
                                        <span><strong>Kosár</strong></span>
                                        <p class="font-Inter-Regular mt-1 text-xs">
                                            Válogass kiemelt kategóriáinkból és szerkeszd meg egyedi ajándékodat!
                                        </p>
                                    </div>
                                </nuxt-link>
                            </div>
                            <div class="col-span-12 hidden lg:flex">
                                <div class="flex items-end w-full">
                                    <div
                                        class="pt-4 w-full mt-11 border-t border-opacity-10 flex items-center justify-between border-black"
                                    >
                                        <div class="flex items-center gap-x-8 text-black">
                                            <span class="text-xs"
                                                >© Kartapolis {{ date }} &nbsp;|&nbsp; Minden jog fenntartva!</span
                                            >
                                            <div class="flex gap-x-4">
                                                <nuxt-link to="/impresszum" class="text-xs hover:underline"
                                                    >Impresszum</nuxt-link
                                                >
                                                <nuxt-link
                                                    to="/altalanos-szerzodesi-feltetelek"
                                                    class="text-xs hover:underline"
                                                >
                                                    ÁSZF</nuxt-link
                                                >
                                            </div>
                                        </div>
                                        <div class="flex gap-x-6">
                                            <a
                                                class="social-anchor social-anchor--fb"
                                                href="/"
                                                aria-label="social"
                                                title="Social fb"
                                            ></a>
                                            <a
                                                class="social-anchor social-anchor--tweet"
                                                href="/"
                                                aria-label="social"
                                                title="Social Tweet"
                                            ></a>
                                            <a
                                                class="social-anchor social-anchor--insta"
                                                href="/"
                                                aria-label="social"
                                                title="Social Insta"
                                            ></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 lg:col-span-5">
                        <div
                            class="footer-right w-full h-full relative lg:pl-16 z-1 mt-8 pt-8 lg:pt-0 lg:mt-0 bg-blue-900"
                        >
                            <div class="flex flex-col justify-between h-full">
                                <NewsletterForm :index="2" />
                                <div class="mt-10 lg:mt-0">
                                    <span class="font-Inter-Bold text-white">Elfogadott bankkártyák:</span>
                                    <div class="bg-white rounded">
                                        <img
                                            class="w-full mt-4 border-2 border-white rounded"
                                            :src="require('~/assets/images/logos/barion-card-strip-intl.svg')"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="col-span-12 block lg:hidden mb-8 lg:mb-0">
                                    <div class="flex items-center">
                                        <div
                                            class="pt-4 w-full mt-4 lg:mt-11 border-t border-opacity-10 flex flex-col lg:flex-row lg:items-center justify-between border-black"
                                        >
                                            <div
                                                class="flex lg:items-center gap-x-4 lg:gap-x-8 text-white lg:text-black"
                                            >
                                                <span class="text-xs"
                                                    >© Kartapolis 2004-2023 &nbsp;&nbsp;| Minden jog fenntartva!</span
                                                >
                                                <div class="flex gap-x-4">
                                                    <nuxt-link to="" class="text-xs hover:underline"
                                                        >Impresszum
                                                    </nuxt-link>
                                                    <nuxt-link to="" class="text-xs hover:underline"> ÁSZF</nuxt-link>
                                                </div>
                                            </div>
                                            <div class="flex gap-x-6 mt-4 lg:mt-0">
                                                <a class="social-anchor social-anchor--fb" href="/"></a>
                                                <a class="social-anchor social-anchor--tweet" href="/"></a>
                                                <a class="social-anchor social-anchor--insta" href="/"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import Logo from '@/components/UI/Logo.vue';
import NewsletterForm from '@/components/Forms/NewsletterForm.vue';

export default {
    name: 'Footer',
    components: {
        Logo,
        NewsletterForm,
    },
    props: {
        red: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            categories: [],
            content: [],
            pages: [],
            isCategoriesVisible: false,
            isInformationsVisible: false,
        };
    },
    async fetch() {
        await Promise.all([this.$axios.$get('/pages'), this.$axios.$get('/categories')])
            .then(([pagesRres, categoriesRres]) => {
                if (pagesRres.result === 'success') {
                    this.pages = pagesRres.data;
                }
                if (categoriesRres.result === 'success') {
                    this.categories = categoriesRres.data;
                }
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });
    },
    computed: {
        date() {
            const now = new Date();
            return now.getFullYear();
        },
        highlightedInformations() {
            return this.informations.filter((item) => {
                return item.highlighted;
            });
        },
    },
};
</script>

<style>
footer {
    box-shadow: 0 4px 100px rgba(0, 0, 0, 0.05);

    .checkmark {
        border-color: white !important;
    }

    input[type='checkbox']:checked + .checkmark {
        background-color: white !important;
    }

    .p-inputtext {
        border-color: transparent !important;
        transition: 0.25s ease;
    }

    .p-inputtext:not(:disabled):hover {
        outline: 2px solid rgba(255, 255, 255, 0.2) !important;
    }

    .p-inputtext:not(:disabled):focus {
        outline: 6px solid rgba(255, 255, 255, 0.25) !important;
    }
}

.social-anchor {
    @apply w-5 h-6 block bg-contain bg-center bg-no-repeat;

    &--fb {
        background-image: url('~/assets/images/icons/facebook-icon.svg');
        @media (max-width: 992px) {
            background-image: url('~/assets/images/icons/facebook-icon-white.svg');
        }
    }

    &--fb-white {
        background-image: url('~/assets/images/icons/facebook-icon-white.svg');
    }

    &--tweet {
        background-image: url('~/assets/images/icons/tweet-icon.svg');
        @media (max-width: 992px) {
            background-image: url('~/assets/images/icons/tweet-icon-white.svg');
        }
    }

    &--tweet-white {
        background-image: url('~/assets/images/icons/tweet-icon-white.svg');
    }

    &--insta {
        background-image: url('~/assets/images/icons/insta-icon.svg');
        @media (max-width: 992px) {
            background-image: url('~/assets/images/icons/insta-icon-white.svg');
        }
    }

    &--insta-white {
        background-image: url('~/assets/images/icons/insta-icon-white.svg');
    }
}

.footer-lg-btn {
    @apply transition-all;

    &:hover {
        @apply border-opacity-100 bg-opacity-[4%];
    }
}

.footer-right {
    &::before {
        content: '';
        @apply bg-blue-900  absolute -left-5 lg:left-0 top-0 w-screen h-[calc(100vh+100vh)] lg:h-[120vh] lg:-top-16 z-[-1];
    }
    .feedback-message-title,
    .feedback-message-subtitle {
        @apply text-white;
    }
    &--red {
        &::before {
            @apply bg-red-200;
        }
    }
}

.card-logo {
    box-shadow: 2px 2px 3.58189px rgba(0, 0, 0, 0.1);
    @apply bg-no-repeat bg-contain bg-center;

    &--visa {
        background-image: url('~/assets/images/logos/visa.png');
    }

    &--mastercard {
        background-image: url('~/assets/images/logos/mastercard.png');
    }

    &--maestro {
        background-image: url('~/assets/images/logos/maestro.png');
    }
}

.footer-block-title {
    @apply flex items-center justify-between;
    @media (max-width: 996px) {
        &::after {
            content: '';
            @apply w-3 h-3 bg-center bg-contain origin-center rotate-90 bg-no-repeat origin-center transition-all;
            background-image: url('~/assets/images/icons/chevron-right-black.svg');
        }
    }

    &.active {
        &::after {
            @apply -rotate-90;
        }
    }
}
</style>
